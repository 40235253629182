var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"mx-1"},[_c('v-col',{staticClass:"px-5 py-5",attrs:{"cols":"12"}},[_c('p',{staticClass:"headline-color"},[_vm._v("Perubahan Keluarga")]),_c('v-divider'),_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{staticClass:"my-0",attrs:{"cols":"12","xl":"3","lg":"3","md":"6","sm":"6","xs":"12"}},[_c('v-text-field',{attrs:{"placeholder":"Masukan Nama / NIP Pegawai","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"my-0",attrs:{"cols":"12","xl":"3","lg":"3","md":"6","sm":"6","xs":"12"}},[_c('v-btn',{staticClass:"px-10",attrs:{"color":"#FBB005"},on:{"click":_vm.getHistoryList}},[_c('span',{staticClass:"subtitle-2 text-capitalize"},[_vm._v("Cari")])])],1)],1),_c('v-data-table',{staticClass:"mt-10",attrs:{"id":"table-custom","multi-sort":"","headers":_vm.headers,"items":_vm.histories,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItem,"footer-props":{
          'items-per-page-options': _vm.rowsPerPageItems
        },"loading-text":"Loading... Please wait"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.NIP_nama`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.NIP)+" - "+_vm._s(item.nama)+" ")]}},{key:`item.created_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("date")(item.created_at))+" ")]}},{key:`item.due_date`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("date")(item.due_date))+" ")]}},{key:`item.validation`,fn:function({ item }){return [(item.validation && item.validation == 100001)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("info")])],1)]}}],null,true)},[_c('span',[_vm._v("Telah Disetujui oleh Admin Satker")])])],1):(item.validation && item.validation != 100001)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("info")])],1)]}}],null,true)},[_c('span',[_vm._v("Telah Disetujui oleh Admin Satker")])])],1):_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("info")])],1)]}}],null,true)},[_c('span',[_vm._v("Menunggu Persetujuan Admin Satker")])])],1)]}},{key:`item.actions`,fn:function({ item }){return [_c('v-card-actions',{staticClass:"pa-0"},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":() => _vm.handleDetail(item)}},[_vm._v("LIHAT")]),(_vm.isApprovePegawai)?_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","color":"success","loading":_vm.approveLoading,"disabled":_vm.isAdminPusat &&
                  (!item.validation || item.validation != '100001')},on:{"click":() => _vm.handleApprove(item, 'APPROVE')}},[_vm._v("SETUJUI")]):_vm._e(),(_vm.isDeclinePegawai)?_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","color":"error","loading":_vm.rejectLoading,"disabled":_vm.isAdminPusat &&
                  (!item.validation || item.validation != '100001')},on:{"click":() => _vm.handleApprove(item, 'REJECT')}},[_vm._v("TOLAK")]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c('ContentNotFound',{attrs:{"message":"Data pegawai dan presensi tidak ada"}})]},proxy:true}],null,true)})],1)],1),_c('DataKeluargaDialog',{ref:"dataKeluarga",attrs:{"disabled":true},on:{"on-refresh":function($event){return _vm.$emit('on-refresh')}},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.isApprovePegawai)?_c('v-btn',{staticClass:"ml-1 px-12",attrs:{"small":"","color":"success","loading":_vm.approveLoading,"disabled":_vm.isAdminPusat &&
            (!_vm.selectedItem.validation || _vm.selectedItem.validation != '100001')},on:{"click":() => _vm.handleApprove(_vm.selectedItem, 'APPROVE')}},[_vm._v("SETUJUI")]):_vm._e(),(_vm.isDeclinePegawai)?_c('v-btn',{staticClass:"ml-1 px-12",attrs:{"small":"","color":"error","loading":_vm.rejectLoading,"disabled":_vm.isAdminPusat &&
            (!_vm.selectedItem.validation || _vm.selectedItem.validation != '100001')},on:{"click":() => _vm.handleApprove(_vm.selectedItem, 'REJECT')}},[_vm._v("TOLAK")]):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }