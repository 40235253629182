import BaseInstance from "../base.instance";
import {
  SIMPEG_DATA_KELUARGA_LIST_PENGAJUAN,
  SIMPEG_DATA_KELUARGA_DETAIL_PENGAJUAN,
  SIMPEG_DATA_KELUARGA_VALIDATION,
  SIMPEG_DATA_KELUARGA_APPROVAL
} from "../constants";

const KeluargaSubmissionService = {
  getList(data) {
    return BaseInstance.post(SIMPEG_DATA_KELUARGA_LIST_PENGAJUAN, data);
  },
  getDetail(data) {
    return BaseInstance.post(SIMPEG_DATA_KELUARGA_DETAIL_PENGAJUAN, data);
  },
  validation(data) {
    return BaseInstance.post(SIMPEG_DATA_KELUARGA_VALIDATION, data);
  },
  approve(data) {
    return BaseInstance.post(SIMPEG_DATA_KELUARGA_APPROVAL, data);
  }
};

export default KeluargaSubmissionService;
