<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12" class="px-5 py-5">
        <p class="headline-color">Perubahan Keluarga</p>
        <v-divider />
        <v-row align="end">
          <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
            <v-text-field
              placeholder="Masukan Nama / NIP Pegawai"
              v-model="search"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
            <v-btn color="#FBB005" class="px-10" @click="getHistoryList">
              <span class="subtitle-2 text-capitalize">Cari</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          id="table-custom"
          class="mt-10"
          multi-sort
          :headers="headers"
          :items="histories"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalItem"
          :footer-props="{
            'items-per-page-options': rowsPerPageItems
          }"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.NIP_nama`]="{ item }">
            {{ item.NIP }} - {{ item.nama }}
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | date }}
          </template>
          <template v-slot:[`item.due_date`]="{ item }">
            {{ item.due_date | date }}
          </template>
          <template v-slot:[`item.validation`]="{ item }">
            <div v-if="item.validation && item.validation == 100001">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="success">info</v-icon>
                  </v-btn>
                </template>
                <span>Telah Disetujui oleh Admin Satker</span>
              </v-tooltip>
            </div>
            <div v-else-if="item.validation && item.validation != 100001">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="error">info</v-icon>
                  </v-btn>
                </template>
                <span>Telah Disetujui oleh Admin Satker</span>
              </v-tooltip>
            </div>
            <div v-else>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="primary">info</v-icon>
                  </v-btn>
                </template>
                <span>Menunggu Persetujuan Admin Satker</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-card-actions class="pa-0">
              <v-btn small color="primary" @click="() => handleDetail(item)"
                >LIHAT</v-btn
              >
              <v-btn
                v-if="isApprovePegawai"
                class="ml-1"
                small
                color="success"
                :loading="approveLoading"
                @click="() => handleApprove(item, 'APPROVE')"
                :disabled="
                  isAdminPusat &&
                    (!item.validation || item.validation != '100001')
                "
                >SETUJUI</v-btn
              >
              <v-btn
                v-if="isDeclinePegawai"
                class="ml-1"
                small
                color="error"
                :loading="rejectLoading"
                @click="() => handleApprove(item, 'REJECT')"
                :disabled="
                  isAdminPusat &&
                    (!item.validation || item.validation != '100001')
                "
                >TOLAK</v-btn
              >
            </v-card-actions>
          </template>
          <template v-slot:no-data>
            <ContentNotFound message="Data pegawai dan presensi tidak ada" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DataKeluargaDialog
      ref="dataKeluarga"
      @on-refresh="$emit('on-refresh')"
      :disabled="true"
    >
      <template v-slot:action>
        <v-btn
          v-if="isApprovePegawai"
          class="ml-1 px-12"
          small
          color="success"
          :loading="approveLoading"
          @click="() => handleApprove(selectedItem, 'APPROVE')"
          :disabled="
            isAdminPusat &&
              (!selectedItem.validation || selectedItem.validation != '100001')
          "
          >SETUJUI</v-btn
        >
        <v-btn
          v-if="isDeclinePegawai"
          class="ml-1  px-12"
          small
          color="error"
          :loading="rejectLoading"
          @click="() => handleApprove(selectedItem, 'REJECT')"
          :disabled="
            isAdminPusat &&
              (!selectedItem.validation || selectedItem.validation != '100001')
          "
          >TOLAK</v-btn
        >
      </template>
    </DataKeluargaDialog>
  </div>
</template>
<script>
import KeluargaSubmissionService from "@/services/resources/keluarga.submission.service";
import { mapGetters } from "vuex";
import { ACTION_ACCESS } from "@/common/constant";

const ContentNotFound = () => import("@/components/Content/NotFound");
const DataKeluargaDialog = () => import("@/components/Dialog/DataKeluarga");

export default {
  components: {
    ContentNotFound,
    DataKeluargaDialog
  },
  data() {
    return {
      type: null,
      rejectLoading: false,
      approveLoading: false,
      headers: [
        { text: "NIP - NAMA", value: "NIP_nama", sortable: true },
        { text: "PENGUBAHAN DATA", value: "type_transname", sortable: false },
        { text: "TANGGAL PENGUBAHAN", value: "created_at", sortable: false },
        { text: "JENIS", value: "jenis", sortable: false },
        { text: "OLEH", value: "created_by_name", sortable: false },
        { text: "BATAS AKHIR", value: "due_date", sortable: false },
        { text: "STATUS", value: "validation", sortable: false },
        { text: "AKSI", value: "actions", sortable: false }
      ],
      totalItem: 0,
      pageCount: 0,
      loading: true,
      options: {
        sortBy: ["created_by"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      histories: [],

      // Form
      search: null,
      selectedItem: null
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    isApprovePegawai() {
      return ACTION_ACCESS.PERUBAHAN_DATA_PEGAWAI.APPROVE.find(
        d => d == this.currentUser.role
      );
    },
    isDeclinePegawai() {
      return ACTION_ACCESS.PERUBAHAN_DATA_PEGAWAI.DECLINE.find(
        d => d == this.currentUser.role
      );
    },
    isAdminSatker() {
      return this.currentUser.role === "ADMIN_SATKER";
    },
    isAdminPusat() {
      return this.currentUser.role === "ADMIN_PUSAT";
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getHistoryList);
      },
      deep: true
    }
  },
  methods: {
    handleApprove(item, type = "APPROVE") {
      let action,
        title = null;
      if (type == "APPROVE") {
        title = "Setujui";
        action = 100001;
      } else if (type == "REJECT") {
        title = "Menolak";
        action = 100002;
      } else if (type == "CANCEL") {
        title = "Membatalkan";
        action = 100003;
      }
      this.$confirm({
        title: `${title} Perubahan`,
        message: `Apakah anda yakin akan ${title} perubahan ini?`,
        button: {
          no: "Batal",
          yes: `${title}`
        },
        isRemark: type == "REJECT",
        callback: (confirm, remark) => {
          if (confirm) {
            if (this.isAdminSatker) {
              let payload = {
                id: item.id,
                validation: action,
                remark
              };
              this.validationHistory(payload, type.toLowerCase());
            } else {
              let payload = {
                id: item.id,
                stt: action,
                remark
              };
              this.approveHistory(payload, type.toLowerCase());
            }
          }
        }
      });
    },
    handleDetail(item) {
      this.selectedItem = item;
      this.getHistoryDetail(item);
    },
    // Service
    async getHistoryList() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await KeluargaSubmissionService.getList({
          filter: {
            search: this.search || ""
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let histories = list;
              histories.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.histories = histories;
              this.totalItem = parseInt(countTotal);
              this.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getHistoryDetail(item) {
      try {
        this.detailLoading = true;
        await KeluargaSubmissionService.getDetail({
          id: item.id
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              console.log("data", data);
              this.$refs.dataKeluarga.edit({
                NIP: data.NIP,
                hubungan: data.hubungan,
                kelamin: data.kelamin == "1" ? "Pria" : "Wanita",
                nama: data.nama,
                tempat_lahir: data.tempat_lahir,
                tanggal_lahir: data.tgl_lahir,
                tanggal_nikah: data.tgl_nikah,
                pekerjaan: data.pekerjaan,
                keterangan: data.keterangan
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.detailLoading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async approveHistory(data, type = "approve") {
      try {
        this[type + "Loading"] = true;
        await KeluargaSubmissionService.approve(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.$refs.dataKeluarga.dismiss();
              this.getHistoryList();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this[type + "Loading"] = false;
          });
      } catch (err) {
        console.error(err);
      }
    },
    async validationHistory(data, type = "approve") {
      try {
        this[type + "Loading"] = true;
        await KeluargaSubmissionService.validation(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.$refs.dataKeluarga.dismiss();
              this.getHistoryList();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this[type + "Loading"] = false;
          });
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>
<style lang="scss">
#btn-type .v-btn--contained {
  background-color: #ffffff !important;
  .v-btn__content {
    color: #717171 !important;
  }
}
#btn-type .v-btn--active {
  background-color: #1792e6 !important;
  .v-btn__content {
    color: #ffffff !important;
  }
}
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
